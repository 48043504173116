<template>
  <d-svg title="0.5l nước">
    <svg
      class="mx-auto my-0"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="40" cy="40" r="38.5" stroke="#990808" stroke-width="3" />
      <path
        d="M58.9 25.8438L59.1152 23.0312H61.6562V20.2188H18.3438V23.0312H20.8848L21.1 25.8438H16V27.25C16 33.3869 18.2892 39.1711 22.4612 43.6307L23.6972 59.7812H56.3027L57.5387 43.6307C61.7108 39.1711 64 33.3869 64 27.25V25.8438H58.9ZM18.8586 28.6562H21.3152L22.0708 38.528C20.243 35.6328 19.0965 32.2667 18.8586 28.6562ZM53.6973 56.9688H26.3028L25.4917 46.3714C29.6443 49.5357 34.69 51.25 40 51.25C45.31 51.25 50.3557 49.5357 54.5083 46.3714L53.6973 56.9688ZM54.8134 42.3832C50.9904 46.1264 45.7602 48.4375 40 48.4375C34.2398 48.4375 29.0096 46.1264 25.1865 42.3832L23.7055 23.0312H56.2945L54.8134 42.3832ZM57.9293 38.528L58.6847 28.6562H61.1414C60.9035 32.2667 59.757 35.6328 57.9293 38.528Z"
        fill="#990808"
      />
    </svg>
  </d-svg>
</template>

<script>
import DSvg from "./DSvg.vue";
export default {
  components: { DSvg },
};
</script>
