<template>
  <div
    class="
      md:bg-green-box1
      md:bg-no-repeat
      md:bg-right-top
      md:bg-cover
      md:bg-unset
      md:w-1/2
      md:pt-450
      md:pb-32
      lg:pt-524
      bg-green-default
      relative
    "
  >
    <div
      class="
        md:top-48 md:w-80 md:my-0 md:m-unset
        w-72
        my-0
        mx-auto
        absolute
        -top-12
        left-1/2
        transform
        -translate-x-1/2
        lg:w-96
      "
    >
      <img
        src="https://phanphoisongiasi.com/upload/elfinder/DUY/product-1.png"
        alt="Product 1"
        class="w-full max-w-full"
      />
    </div>
    <div class="md:pt-7 md:pr-14 md:pb-0 md:pl-5 px-5 pt-52 pb-6">
      <div class="max-w-lg md:ml-auto">
        <slot name="des" />
        <section class="pb-5 mb-5 border-white border-b border-solid">
          <h3
            class="
              md:text-left
              text-center
              mb-2
              text-white
              font-bold
              uppercase
              text-lg
            "
          >
            Định mức lý thuyết:
          </h3>
          <div class="block">
            <p>
              2.0-2.5 m<sup>2</sup>/kg/2 lớp (Tùy thuộc yêu cầu thi công, bề mặt
              và kỹ thuật thi công)
            </p>
          </div>
        </section>
        <section class="block">
          <h3
            class="
              md:text-left
              text-center
              mb-2
              text-white
              font-bold
              uppercase
              text-lg
            "
          >
            Hệ thống sản phẩm đề nghị:
          </h3>
          <slot name="tut" />
          <div class="pl-10">
            <slot name="note" />
          </div>
        </section>
      </div>
    </div>
    <ButtonHref urlHref="https://phanphoisongiasi.com/son-chong-tham-tuong-kova"
      >Xem Sản Phẩm</ButtonHref
    >
    <div class="md:hidden w-full leading-0">
      <img
        class="w-full max-w-full max-h-36"
        src="https://phanphoisongiasi.com/upload/elfinder/DUY/bg-orange-triangle.png"
      />
    </div>
  </div>
</template>

<script>
import ButtonHref from "./ButtonHref.vue";
export default {
  components: { ButtonHref },
};
</script>
