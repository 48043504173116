<template>
  <Card number="03" title="Phủ 2-3 Lớp Hỗn Hợp CT-11A Plus lên bề mặt"
    ><img-5
  /></Card>
</template>

<script>
import Card from "./Card.vue";
import Img5 from "./Img5.vue";
export default {
  components: { Card, Img5 },
};
</script>
