<template>
  <div class="flex flex-col w-32 gap-2">
    <slot />
    <h4 class="font-bold text-red-default text-center text-base uppercase">
      {{ title }}
    </h4>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
