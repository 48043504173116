<template>
  <div class="flex flex-col bg-white py-4 w-full rounded-lg">
    <div
      class="hidden md:flex flex-row justify-center w-full h-auto mb-7 mx-auto"
    >
      <span
        class="
          flex
          justify-center
          bg-d-dark
          text-white text-lg
          font-bold
          items-center
          w-12
          h-12
          rounded-full
        "
        >{{ number }}</span
      >
    </div>
    <h3 class="font-bold uppercase text-lg mx-auto mb-6 text-center w-full">
      {{ title }}
    </h3>
    <div class="flex flex-row gap-4 justify-center w-full">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    number: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
