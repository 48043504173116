<template>
  <a
    :href="urlHref"
    class="
      hover:bg-white hover:text-blue-default hover:shadow-md
      mb-10
      block
      max-w-140
      my-0
      mx-auto
      py-2
      px-3
      rounded
      text-sm text-center text-white
      uppercase
      bg-blue-default
      transition-all
      duration-300
      cursor-pointer
      md:py-3
      md:px-7
      md:mb-0
      md:absolute
      md:bottom-12
      md:left-1/4
      md:inline-block
      md:max-w-unset
      md:transform
      md:translate-x-1/2
    "
    ><slot
  /></a>
</template>

<script>
export default {
  props: {
    urlHref: {
      type: String,
      default: "",
    },
  },
};
</script>
