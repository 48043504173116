<template>
  <div class="app">
    <HeaderImg />
    <div class="md:bg-kova-laptop bg-kova-mobile bg-cover w-full h-full">
      <SectionProperties
        ><div
          v-for="property in properties"
          :key="property.id"
          class="md:mb-2 md:flex-0025 md:max-w-1/4 mb-7 flex-0050 max-w-1/2"
        >
          <CardProperty :post="property" /></div
      ></SectionProperties>
      <div
        class="
          opacity-100
          transform
          scale-3d
          delay-600
          block
          transition-all
          duration-100
        "
      >
        <div class="md:flex relative">
          <ProductLeft>
            <template #des>
              <ul
                class="
                  list-none
                  pb-5
                  mb-5
                  border-white border-b border-solid
                  c-ul1
                "
              >
                <li
                  v-for="list in desGreen"
                  :key="list.id"
                  class="mb-5 last:mb-0 relative pl-5 text-lg font-bold"
                >
                  {{ list.text }}
                </li>
              </ul>
            </template>
            <template #tut>
              <ul class="mb-7 list-none c-ul2">
                <li
                  v-for="list in tutGreen"
                  :key="list.id"
                  class="relative pl-10 pt-2"
                >
                  <span
                    class="
                      absolute
                      top-px
                      -left-1
                      pt-1
                      w-7
                      h-7
                      font-bold
                      text-base text-white text-center
                      bg-red-default
                      rounded-full
                    "
                    >{{ list.id }}</span
                  >
                  <div class="pb-7">
                    <h4 class="text-base mb-1 font-medium">
                      <strong>{{ list.heading }}</strong>
                    </h4>
                    <p class="last:mb-0 mx-0 mt-0 mb-5">
                      {{ list.des
                      }}<sup v-show="list.sup">({{ list.sup }})</sup>
                    </p>
                  </div>
                </li>
              </ul>
            </template>
            <template #note
              ><p
                v-for="note in notesGreen"
                :key="note.id"
                class="mx-0 my-0 last:mb-5"
              >
                <span v-show="note.sup">({{ note.sup }})</span>
                <strong v-show="note.strong">{{ note.strong }}</strong>
                {{ note.text }}
              </p></template
            >
          </ProductLeft>
          <ProductRight
            ><template #des>
              <ul
                class="
                  list-none
                  pb-5
                  mb-5
                  border-white border-b border-solid
                  c-ul1
                "
              >
                <li
                  v-for="list in desOrange"
                  :key="list.id"
                  class="mb-5 last:mb-0 relative pl-5 text-lg font-bold"
                >
                  {{ list.text }}
                </li>
              </ul>
            </template>
            <template #tut>
              <ul class="mb-7 list-none c-ul2">
                <li
                  v-for="list in tutOrange"
                  :key="list.id"
                  class="relative pl-10 pt-2"
                >
                  <span
                    class="
                      absolute
                      top-px
                      -left-1
                      pt-1
                      w-7
                      h-7
                      font-bold
                      text-base text-white text-center
                      bg-red-default
                      rounded-full
                    "
                    >{{ list.id }}</span
                  >
                  <div class="pb-7">
                    <h4 class="text-base mb-1 font-medium">
                      <strong>{{ list.heading }}</strong>
                    </h4>
                    <p class="last:mb-0 mx-0 mt-0 mb-5">
                      {{ list.des
                      }}<sup v-show="list.sup">({{ list.sup }})</sup>
                    </p>
                  </div>
                </li>
              </ul>
            </template>
            <template #note
              ><p
                v-for="note in notesOrange"
                :key="note.id"
                class="mx-0 my-0 last:mb-5"
              >
                <span v-show="note.sup">({{ note.sup }})</span>
                <strong v-show="note.strong">{{ note.strong }}</strong>
                {{ note.text }}
              </p></template
            ></ProductRight
          >
        </div>
      </div>
      <SectionRecipe
        class="pt-16 md:pt-32"
        title="Công thức thi công chống thấm tối ưu"
        ><Recipe1 /><Recipe2 /><Recipe3
      /></SectionRecipe>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";
import CardProperty from "./components/CardProperty.vue";
import HeaderImg from "./components/HeaderImg.vue";
import SectionProperties from "./components/SectionProperties.vue";
import ProductLeft from "./components/ProductLeft.vue";
import ProductRight from "./components/ProductRight.vue";
import SectionRecipe from "./components/SectionRecipe.vue";
import Recipe1 from "./components/Recipe1.vue";
import Recipe3 from "./components/Recipe3.vue";
import Recipe2 from "./components/Recipe2.vue";
export default {
  name: "App",
  components: {
    CardProperty,
    HeaderImg,
    SectionProperties,
    ProductLeft,
    ProductRight,
    SectionRecipe,
    Recipe1,
    Recipe3,
    Recipe2,
  },
  setup() {
    const properties = reactive([
      {
        id: 1,
        text: "Gốc xi măng 100% hệ nước",
        srcUrl: "https://phanphoisongiasi.com/upload/elfinder/DUY/icon-1.png",
      },
      {
        id: 2,
        text: "Bám dính tốt với bê tông, vữa xi măng",
        srcUrl: "https://phanphoisongiasi.com/upload/elfinder/DUY/icon-2.png",
      },
      {
        id: 3,
        text: "Chịu mài mòn, kháng kiềm",
        srcUrl: "https://phanphoisongiasi.com/upload/elfinder/DUY/icon-3.png",
      },
      {
        id: 4,
        text: "Độ bền lên đến 15 năm",
        srcUrl: "https://phanphoisongiasi.com/upload/elfinder/DUY/icon-4.png",
      },
    ]);
    const desGreen = reactive([
      {
        id: 1,
        text: "Áp dụng cho bề mặt Tường đứng.",
      },
      {
        id: 2,
        text: "Khả năng chịu tia UV cao, chịu thời tiết khắc nhiệt.",
      },
      {
        id: 3,
        text: "Thẩm thấu 1 phần của vữa, tăng khả năng chống thấm 2 chiều.",
      },
    ]);
    const desOrange = reactive([
      {
        id: 1,
        text: "Áp dụng cho bề mặt Sàn ngang.",
      },
      {
        id: 2,
        text: "Khả năng chịu ngâm ngập trong nước tốt.",
      },
      {
        id: 3,
        text: "Sử dụng cho sàn nhà vệ sinh, tầng hầm, sân thượng.",
      },
    ]);
    const tutGreen = reactive([
      {
        id: 1,
        heading: "Chống thấm (2-3 lớp):",
        des: "Chất Chống Thấm KOVA CT-11A Plus Tường",
        sup: "*",
      },
      {
        id: 2,
        heading: "Bột trét/Mastic (2 lớp):",
        des: "Bột trét/Mastic Dẻo Ngoại Thất KOVA",
      },
      {
        id: 3,
        heading: "Sơn lót (1 lớp):",
        des: "Sơn Lót Ngoại Thất Kháng Kiềm KOVA",
      },
      {
        id: 4,
        heading: "Sơn lót (2 lớp):",
        des: "Sơn Phủ Ngoại Thất KOVA; Sơn trang trí đặc biệt KOVA",
      },
    ]);
    const tutOrange = reactive([
      {
        id: 1,
        heading: "Vết nứt (Nếu có):",
        des: "Chất Chống Thấm Co Giãn CT-14",
        sup: "*",
      },
      {
        id: 2,
        heading: "Chống thấm (2-3 lớp):",
        des: "Chất Chống Thấm Cao Cấp KOVA CT-11A Plus Sàn",
        sup: "*",
      },
      {
        id: 3,
        heading: "Lớp bảo vệ(**):",
        des: "Sơn Lót Ngoại Thất Kháng Kiềm KOVA",
        sup: "***",
      },
    ]);
    const notesGreen = reactive([
      {
        id: 1,
        sup: "*",
        text: "Áp dụng chất chống thấm co giãn CT-14 cho những vết nứt lớn.",
      },
      {
        id: 2,
        strong: "Lưu ý:",
        text: "Nếu sử dụng chất chống thấm cao cấp KOVA CT-11A Plus Tường cho tường nội thất thì vẫn phải dùng hệ sơn trang trí trong nhà.",
      },
    ]);
    const notesOrange = reactive([
      {
        id: 1,
        sup: "*",
        text: "Gia cố thêm vải thủy tinh ở giáp mí tường và sàn hoặc những vị trí dễ nứt",
      },
      {
        id: 2,
        sup: "**",
        text: "Để tăng tuổi thọ của chống thấm, nên sử dụng lớp vữa bảo vệ",
      },
      {
        id: 3,
        sup: "***",
        text: `Phối trộn thêm 4-5 kg phụ gia CT-11B cho 1 mét khối hồ để tăng độ bền cho lớp bảo vệ`,
      },
    ]);

    return {
      properties,
      desGreen,
      tutGreen,
      notesGreen,
      desOrange,
      tutOrange,
      notesOrange,
    };
  },
};
</script>

<style lang="postcss" scoped>
.app {
  @apply font-normal text-base text-d-dark;
}
</style>
