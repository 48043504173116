<template>
  <div
    class="
      opacity-100
      transform
      scale-3d
      delay-0
      block
      transition-all
      duration-1000
      md:py-16 md:px-0
      pt-10
    "
  >
    <div class="container pr-4 pl-4 mx-auto">
      <section class="max-w-3xl mx-auto mt-0 mb-10 text-center">
        <h2
          class="
            font-utm-h
            md:text-4xl
            text-2xl
            md:font-bold
            font-medium
            text-red-default
            uppercase
          "
        >
          CHẤT CHỐNG THẤM ĐƯỢC THẦU THỢ TIN DÙNG HƠN 20 NĂM
        </h2>
        <div class="md:text-2xl text-lg">
          <p class="m-0">
            Chất chống thấm KOVA CT-11A Plus là sản phẩm đầu tiên của KOVA,
          </p>
          <p class="mx-0 my-0 mb-5">với ưu điểm vượt trội:</p>
        </div>
      </section>
      <div class="md:mb-5 flex flex-wrap -mx-4">
        <slot />
      </div>
    </div>
    <div class="md:hidden block w-full leading-0">
      <img
        src="https://phanphoisongiasi.com/upload/elfinder/DUY/bg-green-triangle.png"
        alt=""
        class="max-h-36 w-full"
      />
    </div>
  </div>
</template>
