<template>
  <section class="opacity-100 transform scale-3d transition-all duration-100">
    <div class="container px-3 mx-auto">
      <h2
        class="
          md:mb-12 md:text-4xl
          text-2xl
          font-bold
          text-center
          delay-600
          block
          font-utm-h
          text-red-default
          uppercase
        "
      >
        {{ title }}
      </h2>
      <div class="md:pt-16 md:pb-14 px-10 py-10 w-full h-auto">
        <div class="flex flex-col gap-6 md:gap-8 xl:flex-row">
          <slot />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>
