<template>
  <Card number="02" title="Trộn Hỗn hợp với 1kg chất chống thấm"
    ><img-3 /><plus /><img-4
  /></Card>
</template>

<script>
import Card from "./Card.vue";
import Img3 from "./Img3.vue";
import Img4 from "./Img4.vue";
import Plus from "./Plus.vue";
export default {
  components: { Card, Img3, Plus, Img4 },
};
</script>
