<template>
  <Card number="01" title="Trộn xi măng với nước"
    ><img-1 /><plus /><img-2
  /></Card>
</template>

<script>
import Card from "./Card.vue";
import Img1 from "./Img1.vue";
import Img2 from "./Img2.vue";
import Plus from "./Plus.vue";
export default {
  components: { Card, Img1, Plus, Img2 },
};
</script>
