<template>
  <d-svg title="Chất chống thấm CT-11A Plus">
    <img
      class="mx-auto my-0 w-auto h-20"
      src="https://phanphoisongiasi.com/upload/elfinder/DUY/product.png"
    />
  </d-svg>
</template>

<script>
import DSvg from "./DSvg.vue";
export default {
  components: { DSvg },
};
</script>
