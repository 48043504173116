<template>
  <div class="card">
    <div class="mb-2 flex justify-center items-center">
      <img :src="post.srcUrl" class="md:max-w-65 max-w-45" :alt="post.alt" />
    </div>
    <h6 class="md:text-lg mb-0 text-base font-bold uppercase text-center">
      {{ post.text }}
    </h6>
  </div>
</template>

<script>
export default {
  name: "CardProperty",
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
};
</script>
